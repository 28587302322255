import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../../components/navbar";
import Footer from "../../../components/footer";
import Filter from "../../../components/filter";

import { FiChevronLeft, FiChevronRight } from "../../../assets/icons/vander";
import ScrollToTop from "../../../components/scroll-to-top";
import ShopItemCard from "../../../components/shop-item-card";
import axiosInstance from "../../../utils/axiosInstance";
import { handleGetLanguage } from "../../../utils/languageStorage";
import { ClipLoader } from "react-spinners";

export default function ShopGridLeftSidebar() {
    const language = handleGetLanguage();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const genderQuery = queryParams.get("gender");
    const pageQuery = queryParams.get("page");
    const searchQuery = queryParams.get("search");
    const typeQuery = queryParams.get("type");
    const colorQuery = queryParams.get("colors");
    const brandQuery = queryParams.get("brands");
    const sizeQuery = queryParams.get("sizes");
    const orderyQuery = queryParams.get("sort_order");
    const categorieQuery = queryParams.get("categories");
    const priceQuery = queryParams.get("price");

    const updateQuery = (key, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        return `${location.pathname}?${searchParams.toString()}`;
    };

    const removeQuery = (key) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(key);
        return `${location.pathname}?${searchParams.toString()}`;
    };

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = () => {
            setLoading(true);

            const params = new URLSearchParams();
            if (pageQuery) params.append("page", pageQuery);
            if (genderQuery) params.append("gender", genderQuery);
            if (typeQuery) params.append("type", typeQuery);
            if (colorQuery) params.append("colors", colorQuery);
            if (brandQuery) params.append("brand", brandQuery);
            if (sizeQuery) params.append("sizes", sizeQuery);
            if (searchQuery) params.append("search", searchQuery);
            if (orderyQuery) params.append("sort_order", orderyQuery);
            if (categorieQuery) params.append("category", categorieQuery);
            if (priceQuery) params.append("price", priceQuery);

            const url = `/products?${params.toString()}`;

            axiosInstance
                .get(url)
                .then((res) => {
                    setProducts(res.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
        };

        fetchProducts();
    }, [
        searchQuery,
        typeQuery,
        colorQuery,
        brandQuery,
        sizeQuery,
        orderyQuery,
        pageQuery,
        genderQuery,
        categorieQuery,
        priceQuery,
    ]);

    const translations = {
        en: {
            fashion: "Fashion",
            showingItems: `Showing ${products?.from}-${products?.to} of ${products?.total} items`,
            sortPrice: "Sort price:",
            default: "default",
            byLowHigh: "By Low-High",
            byHighLow: "By High-Low",
            Onlybrands: "Onlybrands",
            shop: "Shop",
        },
        ka: {
            fashion: "მოდა",
            showingItems: "ნაჩვენებია 1-16 40 პროდუქტიდან",
            sortPrice: "ფასის სორტირება:",
            default: "ნაგულისხმები",
            byLowHigh: "დაბალი-მაღალი",
            byHighLow: "მაღალი-დაბალი",
            Onlybrands: "მხოლოდ ბრენდები",
            shop: "მაღაზია",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <div className="flex flex-col min-h-screen">
            {/* Navbar */}
            <Navbar navClass="defaultscroll is-sticky" categoriesQuery={categorieQuery} />

            {/* Main Content */}
            <main className="flex-grow">
                <section className="hidden  relative md:table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
                    <div className="container relative"></div>
                </section>
                <section className="py-16 lg:px-5   xl:px-10 2xl:p-24 ">
                    <div className="w-full px-6">
                        {/* Use flex layout for medium screens and above */}
                        <div className="relative  flex gap-6 ">
                            {/* Filter component with sticky behavior on larger screens */}
                            {/* <div className=""> */}
                            {/* Apply sticky positioning only on medium screens and above */}
                            {/* Adjusted top for navbar space */}
                            <Filter
                                searchQuery={searchQuery}
                                typeQuery={typeQuery}
                                genderQuery={genderQuery}
                                colorQuery={colorQuery}
                                brandQuery={brandQuery}
                                sizeQuery={sizeQuery}
                                removeQuery={removeQuery}
                                categorieQuery={categorieQuery}
                                updateQuery={updateQuery}
                            />
                            {/* </div> */}

                            {/* Products grid */}
                            <div className="w-full md:w-3/4 mt-5 md:mt-0">
                                {/* Sorting Dropdown */}
                                <div className="md:flex items-center mb-6">
                                    <label className="font-semibold md:me-2">{t.sortPrice}</label>
                                    <select
                                        onChange={(e) => {
                                            if (e.target.value !== "none") {
                                                navigate(updateQuery("sort_order", e.target.value));
                                            } else {
                                                navigate(removeQuery("sort_order"));
                                            }
                                        }}
                                        className="form-select form-input md:w-36 w-full md:mt-0 mt-1 py-2 px-2 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-100 dark:border-gray-800 focus:ring-0"
                                    >
                                        <option value="none">{t.default}</option>
                                        <option value="asc">{t.byLowHigh}</option>
                                        <option value="desc">{t.byHighLow}</option>
                                    </select>
                                </div>

                                {/* Products Grid */}
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 lg:gap-16 xl:gap-28">
                                    {loading ? (
                                        <div
                                            className="absolute inset-0 flex justify-center items-center w-full"
                                            style={{ minHeight: "300px" }} // Adjust minHeight for better centering in grid
                                        >
                                            <ClipLoader
                                                size={50}
                                                color={"#123abc"}
                                                loading={true}
                                            />
                                        </div>
                                    ) : products?.data && products.data.length > 0 ? (
                                        products.data.map((item, index) => (
                                            <ShopItemCard item={item} key={index} />
                                        ))
                                    ) : (
                                        <div className="col-span-full text-center py-10">
                                            <p className="text-gray-500 dark:text-gray-400">
                                                {language === "ka"
                                                    ? "პროდუქტები არ მოიძებნა"
                                                    : "No products found."}
                                            </p>
                                            <Link
                                                to="/shop"
                                                className="mt-4 inline-block text-orange-600 hover:text-orange-500 transition-colors"
                                            >
                                                {language === "ka"
                                                    ? "უკან დაბრუნება"
                                                    : "Back to Shop"}
                                            </Link>
                                        </div>
                                    )}
                                </div>

                                {/* Pagination */}
                                <div className="grid md:grid-cols-12 grid-cols-1 mt-6">
                                    <div className="md:col-span-12 text-center">
                                        <nav aria-label="Page navigation example">
                                            <ul className="inline-flex items-center -space-x-px paginationUl">
                                                {products?.links &&
                                                    (() => {
                                                        const links = products.links;
                                                        const currentPageIndex = links.findIndex(
                                                            (link) => link.active
                                                        );
                                                        const totalLinks = links.length;
                                                        const maxVisiblePages = 5;

                                                        const startIndex = Math.max(
                                                            0,
                                                            Math.min(
                                                                currentPageIndex -
                                                                    Math.floor(maxVisiblePages / 2),
                                                                totalLinks - maxVisiblePages
                                                            )
                                                        );

                                                        const visibleLinks = links.slice(
                                                            startIndex,
                                                            startIndex + maxVisiblePages
                                                        );

                                                        return (
                                                            <>
                                                                {/* Prev Button */}
                                                                <li>
                                                                    <button
                                                                        onClick={() => {
                                                                            if (links[0]?.url) {
                                                                                const prevPage =
                                                                                    new URL(
                                                                                        links[0].url
                                                                                    ).searchParams.get(
                                                                                        "page"
                                                                                    );
                                                                                navigate(
                                                                                    updateQuery(
                                                                                        "page",
                                                                                        prevPage
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                        disabled={!links[0]?.url}
                                                                        className={`size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500 ${
                                                                            !links[0]?.url
                                                                                ? "cursor-not-allowed bg-orange-500 text-white"
                                                                                : "bg-white"
                                                                        }`}
                                                                    >
                                                                        <FiChevronLeft />
                                                                    </button>
                                                                </li>

                                                                {/* Render Visible Pages */}
                                                                {visibleLinks.map(
                                                                    (linkItem, index) => {
                                                                        let labelContent =
                                                                            linkItem.label;

                                                                        // Replace "prev" and "next" with icons
                                                                        if (
                                                                            labelContent
                                                                                .toLowerCase()
                                                                                .includes("prev") ||
                                                                            labelContent
                                                                                .toLowerCase()
                                                                                .includes("next")
                                                                        ) {
                                                                            return null; // Skip rendering extra labels
                                                                        }

                                                                        return (
                                                                            <li key={index}>
                                                                                <button
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            linkItem.url
                                                                                        ) {
                                                                                            const pageNumber =
                                                                                                new URL(
                                                                                                    linkItem.url
                                                                                                ).searchParams.get(
                                                                                                    "page"
                                                                                                ) ||
                                                                                                linkItem.label;
                                                                                            navigate(
                                                                                                updateQuery(
                                                                                                    "page",
                                                                                                    pageNumber
                                                                                                )
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    disabled={
                                                                                        !linkItem.url ||
                                                                                        linkItem.active
                                                                                    }
                                                                                    className={`size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500 ${
                                                                                        !linkItem.url ||
                                                                                        linkItem.active
                                                                                            ? "cursor-not-allowed bg-orange-500 text-white"
                                                                                            : "bg-white"
                                                                                    }`}
                                                                                >
                                                                                    {linkItem.label}
                                                                                </button>
                                                                            </li>
                                                                        );
                                                                    }
                                                                )}

                                                                {/* Next Button */}
                                                                <li>
                                                                    <button
                                                                        onClick={() => {
                                                                            if (
                                                                                links[
                                                                                    totalLinks - 1
                                                                                ]?.url
                                                                            ) {
                                                                                const nextPage =
                                                                                    new URL(
                                                                                        links[
                                                                                            totalLinks -
                                                                                                1
                                                                                        ].url
                                                                                    ).searchParams.get(
                                                                                        "page"
                                                                                    );
                                                                                navigate(
                                                                                    updateQuery(
                                                                                        "page",
                                                                                        nextPage
                                                                                    )
                                                                                );
                                                                            }
                                                                        }}
                                                                        disabled={
                                                                            !links[totalLinks - 1]
                                                                                ?.url
                                                                        }
                                                                        className={`size-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:border-orange-500 dark:hover:border-orange-500 hover:bg-orange-500 dark:hover:bg-orange-500 ${
                                                                            !links[totalLinks - 1]
                                                                                ?.url
                                                                                ? "cursor-not-allowed bg-orange-500 text-white"
                                                                                : "bg-white"
                                                                        }`}
                                                                    >
                                                                        <FiChevronRight />
                                                                    </button>
                                                                </li>
                                                            </>
                                                        );
                                                    })()}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <Footer />

            {/* Additional Components */}
            <ScrollToTop />
        </div>
    );
}
