import Ck from "../assets/images/brands/CK.png";
import karl from "../assets/images/brands/Karl-Lagerfeld-Logo.png";
import boss from "../assets/images/brands/boss-hugo.png";
import diesel from "../assets/images/brands/diesel-logo-vector-2.png";
import armani from "../assets/images/brands/emporio-armani-logo-vector.png";
import liujio from "../assets/images/brands/liujo.png";
import michael from "../assets/images/brands/michael-kors-logo-vector.png";
import paul from "../assets/images/brands/paul-shark-logo-vector.png";
import pierra from "../assets/images/brands/pierre-cardin.png";
import prada from "../assets/images/brands/prada-logo-vector-1.png";
import salvatore from "../assets/images/brands/salvatore-ferragamo-logo-vector.png";
import timberland from "../assets/images/brands/timberland-logo-vector.png";
import twinset from "../assets/images/brands/twinset.png";

import versace from "../assets/images/brands/versace.png";

const brandData = [
  {
    id: 1,
    title: "Calvin Klein",
    logo: Ck,
  },
  {
    id: 2,
    title: "Karl Lagerfeld",
    logo: karl,
  },
  {
    id: 3,
    title: "Hugo Boss",
    logo: boss,
  },
  {
    id: 4,
    title: "Diesel",
    logo: diesel,
  },
  {
    id: 5,
    title: "Emporio Armani",
    logo: armani,
  },
  {
    id: 6,
    title: "Liu Jo",
    logo: liujio,
  },
  {
    id: 7,
    title: "Michael Kors",
    logo: michael,
  },
  {
    id: 8,
    title: "Paul & Shark",
    logo: paul,
  },
  {
    id: 9,
    title: "Pierre Cardin",
    logo: pierra,
  },
  {
    id: 10,
    title: "Prada",
    logo: prada,
  },
  {
    id: 11,
    title: "Salvatore Ferragamo",
    logo: salvatore,
  },
  {
    id: 12,
    title: "Timberland",
    logo: timberland,
  },
  {
    id: 13,
    title: "Twinset",
    logo: twinset,
  },
  {
    id: 15,
    title: "Versace",
    logo: versace,
  },
];

export default brandData;
