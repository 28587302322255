import { useState, useEffect, useRef } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
    FiSearch,
    FiShoppingCart,
    FiHeart,
    FiUser,
    FiHelpCircle,
    FiLogOut,
    FiCreditCard,
} from "react-icons/fi";
import "react-dropdown/style.css";
import useAuthModal from "../store/authWarningModal";
import useUserData from "../store/userStore";
import axiosInstance from "../utils/axiosInstance";
import { toast } from "sonner";
import { handleDeleteAuthCookie } from "../utils/token";
import useCartStore from "../store/cartStore";
import { handleGetLanguage } from "../utils/languageStorage";
import CategoriesMenu from "./CategoriesMenu";
import Switcher from "./switcher";

export default function Navbar({ navClass, navlight, categoriesQuery }) {
    let [scrolling, setScrolling] = useState(false);
    let [isToggle, setToggle] = useState(false);
    let [manu, setManu] = useState("");

    let [isOpen, setIsOpen] = useState(false);
    let [userManu, setUserManu] = useState(false);
    let dropdownRef = useRef(null);
    let cartRef = useRef(null);
    let userRef = useRef(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const typeQuery = queryParams.get("type");

    const updateQuery = (key, value, targetPath) => {
        const searchParams = new URLSearchParams(location.search);

        searchParams.set(key, value);

        let pathname = targetPath || location.pathname;

        if (pathname.endsWith("/")) {
            pathname = pathname.slice(0, -1);
        }

        return `${pathname}?${searchParams.toString()}`;
    };

    const language = handleGetLanguage();

    const logOutHandler = async () => {
        await axiosInstance.post("/logout").then((res) => {
            handleDeleteAuthCookie();
            removeUserData();
            toast.success(language === "ka" ? "იუზერი წარმატებით გამოვიდა" : "User logged out");
        });
        // TODO catch block is needed?
    };

    useEffect(() => {
        const handleScroll = () => {
            const isScrolling = window.scrollY > 50;
            setScrolling(isScrolling);
        };
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        const userOutsideClick = (e) => {
            if (userRef.current && !userRef.current.contains(e.target)) {
                setUserManu(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("click", handleOutsideClick);
        window.addEventListener("click", userOutsideClick);

        let current = window.location.pathname;
        setManu(current);
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("click", handleOutsideClick);
            window.removeEventListener("click", userOutsideClick);
        };
    }, []);

    const toggleMenu = () => {
        setToggle(!isToggle);
    };

    const { show } = useAuthModal((state) => state);
    const { user, removeUserData } = useUserData((state) => state);
    const { cart } = useCartStore((state) => state);

    const router = useNavigate();

    const [searchValue, setSearchValue] = useState("");

    const searchClickhandler = () => {
        router(`/shop?search=${searchValue}`);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            searchClickhandler();
        }
    };

    const translations = {
        en: {
            categories: "Categories",
            shop: "Shop",
            signIn: "Sign in",
            signUp: "Sign up",
            welcome: "Welcome",
            account: "Account",
            contact: "Contact",
            logout: "Logout",
            searchPlaceholder: "Search...",
            Bags: "Bags",
            Wallets: "Wallets",
            Glasses: "Glasses",
            Watches: "Watches",
            "Winter Hats": "Winter Hats",
            "Summer Hats": "Summer Hats",
            Jewellery: "Jewellery",
            "Womans bags": "Womans bags",
            "Sport bags": "Sport bags",
            "School bags": "School bags",
            "Laptop bags": "Laptop bags",
            "Travel bags": "Travel bags",
            "Womans wallet": "Womans wallet",
            "Mans wallet": "Mans wallet",
            "Womans glasses": "Womans glasses",
            "Mans glasses": "Mans glasses",
            "Womans watches": "Womans watches",
            "Mans watches": "Mans watches",
            "Womans hats": "Womans hats",
            "Mans hats": "Mans hats",
            Earring: "Earring",
            Bracelet: "Bracelet",
            Ring: "Ring",
            Necklace: "Necklace",
            Pendant: "Pendant",
            Cross: "Cross",
        },
        ka: {
            categories: "კატეგორიები",
            shop: "მაღაზია",
            signIn: "შესვლა",
            signUp: "რეგისტრაცია",
            welcome: "მოგესალმებით",
            account: "ანგარიში",
            contact: "კონტაქტი",
            logout: "გასვლა",
            searchPlaceholder: "ძიება...",
            Bags: "ჩანთები",
            Wallets: "საფულეები",
            Glasses: "სათვალეები",
            Watches: "საათები",
            "Winter Hats": "ზამთრის ქუდები",
            "Summer Hats": "ზაფხულის ქუდები",
            Jewellery: "სამკაულები",
            "Womans bags": "ქალბატონების ჩანთები",
            "Sport bags": "სპორტული ჩანთები",
            "School bags": "სასკოლო ჩანთები",
            "Laptop bags": "ლეპტოპის ჩანთები",
            "Travel bags": "სამგზავრო ჩანთები",
            "Womans wallet": "ქალბატონების საფულე",
            "Mans wallet": "მამაკაცების საფულე",
            "Womans glasses": "ქალბატონების სათვალეები",
            "Mans glasses": "მამაკაცების სათვალეები",
            "Womans watches": "ქალბატონების საათები",
            "Mans watches": "მამაკაცების საათები",
            "Womans hats": "ქალბატონების ქუდები",
            "Mans hats": "მამაკაცების ქუდები",
            Earring: "საყურეები",
            Bracelet: "ბრასლეტები",
            Ring: "ბეჭდები",
            Necklace: "ყელსაბამები",
            Pendant: "მონსტრები",
            Cross: "ჯვრები",
        },
    };

    const t = translations[language] || translations.en;

    // New function to handle modal opening and burger menu closing
    const handleShowModal = (modalType) => {
        show(modalType);
        setToggle(false);
    };

    return (
        <nav
            id="topnav"
            className={`flex items-center ${navClass} ${scrolling ? "nav-sticky" : ""}`}
        >
            <div className="w-full xl:container px-2 flex items-center justify-between relative">
                {/* Left: Logo */}
                <Link
                    className="logo relative block text-center hover:scale-110 duration-150"
                    to="/"
                >
                    <h1 className="text-2xl text-black dark:text-white relative z-10">
                        Onlybrands
                    </h1>
                    <h5 className="text-base absolute left-1/2 transform -translate-x-1/2 top-6 whitespace-nowrap">
                        {/* {language === "ka" ? "მხოლოდ ორიგინალი" : "only original"} */}
                        Only Original
                    </h5>
                </Link>

                {/* Center: Navigation menu (hidden on mobile) */}
                <div className="hidden lg:block">
                    <ul className="navigation-menu flex flex-nowrap">
                        <CategoriesMenu categoriesQuery={categoriesQuery} />

                        <li className={`${manu === "/shop" && !typeQuery ? "active" : ""}`}>
                            <Link to="/shop" className="px-0 sub-menu-item">
                                {language === "ka" ? "მაღაზია" : "Shop"}
                            </Link>
                        </li>

                        <li
                            className={`${
                                manu === "/shop" && typeQuery === "sales" ? "active" : ""
                            }`}
                        >
                            <Link
                                to={
                                    location.pathname !== "/shop"
                                        ? updateQuery("type", "sales", "/shop")
                                        : updateQuery("type", "sales")
                                }
                                className="px-0 xl:px-4 sub-menu-item"
                            >
                                {language === "ka" ? "ფასდაკლებები" : "Sales"}
                            </Link>
                        </li>

                        <li className={`${manu === "/rules" ? "active" : ""}`}>
                            <Link to="/rules" className="px-0 sub-menu-item">
                                {language === "ka" ? "საიტის წესები" : "Site Rules"}
                            </Link>
                        </li>
                        <li className={`${manu === "/career" ? "active" : ""}`}>
                            <Link to="/career" className="px-0 sub-menu-item">
                                {language === "ka" ? "რეფერალი" : "Referral"}
                            </Link>
                        </li>
                        <li className={`${manu === "/contact" ? "active" : ""}`}>
                            <Link to="/contact" className="px-0 sub-menu-item">
                                {language === "ka" ? "კონტაქტი" : "contact"}
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Right: Icons and burger bar */}
                <div className="flex items-center space-x-2">
                    {/* Burger bar (mobile only) */}
                    <div className="lg:hidden">
                        <button
                            className={`navbar-toggle ${isToggle ? "open" : ""}`}
                            id="isToggle"
                            onClick={() => toggleMenu()}
                        >
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>

                    {/* Icons */}
                    <ul className="hidden buy-button list-none mb-0 lg:flex items-center space-x-2">
                        <li className="dropdown inline-block relative pe-1" ref={dropdownRef}>
                            <button
                                data-dropdown-toggle="dropdown"
                                className="dropdown-toggle align-middle inline-flex search-dropdown"
                                type="button"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                {navlight === true ? (
                                    <>
                                        <FiSearch className="size-5 dark-icon" />
                                        <FiSearch className="size-5 white-icon text-white" />
                                    </>
                                ) : (
                                    <FiSearch className="size-5" />
                                )}
                            </button>
                            {isOpen && (
                                <div
                                    className={`dropdown-menu absolute overflow-hidden end-0 m-0 mt-5 z-10 md:w-52 w-48 rounded-md bg-white dark:bg-slate-900 shadow dark:shadow-gray-800`}
                                >
                                    <div className="relative">
                                        <FiSearch
                                            onClick={searchClickhandler}
                                            className="absolute size-4 top-[9px] end-3 text-slate-900 dark:text-white cursor-pointer"
                                        />
                                        <input
                                            type="text"
                                            className="h-9 px-3 pe-10 w-full border-0 focus:ring-0 outline-none bg-white dark:bg-slate-900"
                                            name="s"
                                            id="searchItem"
                                            placeholder={t.searchPlaceholder}
                                            value={searchValue}
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            )}
                        </li>

                        <li className="dropdown inline-block relative ps-0.5" ref={cartRef}>
                            {cart.length > 0 && (
                                <p className="absolute top-0 left-0 translate-x-[-50%] translate-y-[-50%] rounded-full bg-red-500 p-1 w-6 h-6 flex items-center justify-center text-white">
                                    {cart.length}
                                </p>
                            )}
                            <button
                                className="dropdown-toggle size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 border border-orange-500 text-white hover:bg-orange-400"
                                type="button"
                                onClick={() => {
                                    if (!user?.id || !user?.email) {
                                        handleShowModal();
                                    } else {
                                        router("/shop-cart");
                                    }
                                }}
                            >
                                <span>
                                    <FiShoppingCart className="h-4 w-4" />
                                </span>
                            </button>
                        </li>

                        <li className="inline-block ps-0.5">
                            <button
                                onClick={() => {
                                    if (!user?.id || !user?.email) {
                                        handleShowModal();
                                    } else {
                                        router("/favorites");
                                    }
                                }}
                                className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-orange-500 text-white hover:bg-orange-400"
                            >
                                <FiHeart data-feather="heart" className="h-4 w-4"></FiHeart>
                            </button>
                        </li>

                        <li className="dropdown inline-block relative ps-0.5" ref={userRef}>
                            {user?.name || user?.email ? (
                                <>
                                    <button
                                        data-dropdown-toggle="dropdown"
                                        className="dropdown-toggle items-center"
                                        type="button"
                                        onClick={() => setUserManu(!userManu)}
                                    >
                                        <span className="size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full border border-orange-500 bg-orange-500 text-white">
                                            <div className="absolute inset-0 flex items-center justify-center bg-gray-300 rounded-full">
                                                <span className="font-bold text-white">
                                                    {user?.name.slice(0, 2).toUpperCase()}
                                                </span>
                                            </div>
                                        </span>
                                    </button>
                                    {userManu && (
                                        <div className="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700">
                                            <ul className="py-2 text-start">
                                                <li className="ms-0">
                                                    <p className="text-slate-400 pt-2 px-4">
                                                        {t.welcome} {user?.name}!
                                                    </p>
                                                </li>

                                                <li className="ms-0">
                                                    <div className="flex items-center font-medium py-2 px-4 dark:text-white/70  dark:hover:text-white">
                                                        <FiCreditCard className="h-4 w-4 me-2" />
                                                        {language === "ka" ? "კრედიტი " : "Credit "}
                                                        : {user.credit}
                                                    </div>
                                                </li>
                                                <li className="ms-0">
                                                    <Link
                                                        to="/user-setting"
                                                        className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                                                    >
                                                        <FiUser className="h-4 w-4 me-2" />
                                                        {t.account}
                                                    </Link>
                                                </li>
                                                <li className="ms-0">
                                                    <Link
                                                        to="/contact"
                                                        className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                                                    >
                                                        <FiHelpCircle className="h-4 w-4 me-2" />
                                                        {t.contact}
                                                    </Link>
                                                </li>
                                                <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                                                <li className="ms-0">
                                                    <button
                                                        onClick={() => {
                                                            logOutHandler();
                                                            router("/");
                                                        }}
                                                        className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-orange-500 dark:hover:text-white"
                                                    >
                                                        <FiLogOut className="h-4 w-4 me-2" />
                                                        {t.logout}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className="hidden lg:flex items-center gap-1">
                                    <button
                                        onClick={() => handleShowModal()}
                                        className="py-1.5 px-2 md:px-3 text-sm rounded-lg bg-transparent border border-black flex items-center hover:bg-gray-100 dark:border-white dark:hover:bg-gray-800"
                                    >
                                        <AiOutlineUser className="md:mr-2" />
                                        <p className="hidden md:block">{t.signIn}</p>
                                    </button>
                                    <button
                                        onClick={() => handleShowModal("Signup")}
                                        className="py-1.5 px-2 md:px-3 text-sm rounded-lg bg-transparent border border-black flex items-center hover:bg-gray-100 dark:border-white dark:hover:bg-gray-800"
                                    >
                                        <AiOutlineUser className="md:mr-2" />
                                        <p className="hidden md:block">{t.signUp}</p>
                                    </button>
                                </div>
                            )}
                        </li>

                        <div
                            className={`${
                                user.name && "translate-y-[4px]"
                            } ml-2 hidden lg:inline-block`}
                        >
                            <Switcher />
                        </div>
                    </ul>
                </div>
            </div>

            {/* Navigation menu for mobile (toggled by burger bar) */}
            <div
                className={`${
                    isToggle ? "block" : "hidden"
                } absolute lg:hidden inset-x-0 top-[100%] pb-2 xl:pb-0 z-10 bg-white dark:bg-black dark:text-white`}
            >
                <ul className={`navigation-menu ${navlight === true ? "nav-light" : ""}`}>
                    <CategoriesMenu categoriesQuery={categoriesQuery} />

                    <li className={`${manu === "/shop" && !typeQuery ? "active" : ""}`}>
                        <Link to="/shop" className="sub-menu-item">
                            {language === "ka" ? "მაღაზია" : "Shop"}
                        </Link>
                    </li>

                    <li className={`${manu === "/shop" && typeQuery === "sales" ? "active" : ""}`}>
                        <Link
                            to={
                                location.pathname !== "/shop"
                                    ? updateQuery("type", "sales", "/shop")
                                    : updateQuery("type", "sales")
                            }
                            className="sub-menu-item"
                        >
                            {language === "ka" ? "ფასდაკლებები" : "Sales"}
                        </Link>
                    </li>

                    <li className={`${manu === "/rules" ? "active" : ""}`}>
                        <Link to="/rules" className="sub-menu-item">
                            {language === "ka" ? "საიტის წესები" : "Site Rules"}
                        </Link>
                    </li>

                    <li className={`${manu === "/favorites" ? "active" : ""}`}>
                        <Link to="/favorites" className="sub-menu-item">
                            {language === "ka" ? "ფავორიტები" : "Favorites"}
                        </Link>
                    </li>

                    <li className={`${manu === "/shop-cart" ? "active" : ""}`}>
                        <Link to="/shop-cart" className="sub-menu-item">
                            {language === "ka" ? "კალათა" : "Cart"}
                        </Link>
                    </li>

                    <li className={`${manu === "/user-setting" ? "active" : ""}`}>
                        <Link to="/user-setting" className="sub-menu-item">
                            {language === "ka" ? "პროფილი" : "Profile"}
                        </Link>
                    </li>
                    <li className={`${manu === "/career" ? "active" : ""}`}>
                        <Link to="/career" className="sub-menu-item">
                            {language === "ka" ? "რეფერალი" : "Referral"}
                        </Link>
                    </li>
                    <li className={`${manu === "/contact" ? "active" : ""}`}>
                        <Link to="/contact" className="sub-menu-item">
                            {language === "ka" ? "კონტაქტი" : "contact"}
                        </Link>
                    </li>
                </ul>

                {!user.name && (
                    <div className="my-2 flex items-center justify-center gap-1">
                        <button
                            onClick={() => handleShowModal()}
                            className="py-1.5 px-2 md:px-3 text-sm rounded-lg bg-orange-500 border flex items-center hover:bg-gray-100 dark:border-white dark:hover:bg-gray-800"
                        >
                            {/* <AiOutlineUser className="md:mr-2" /> */}
                            <p className="text-white">{t.signIn}</p>
                        </button>
                        <button
                            onClick={() => handleShowModal("Signup")}
                            className="py-1.5 px-2 md:px-3 text-sm rounded-lg bg-orange-500 border flex items-center hover:bg-gray-100 dark:border-white dark:hover:bg-gray-800"
                        >
                            {/* <AiOutlineUser className="md:mr-2" /> */}
                            <p className="text-white">{t.signUp}</p>
                        </button>
                    </div>
                )}

                <div className={`mt-2`}>
                    <Switcher />
                </div>
            </div>
        </nav>
    );
}
