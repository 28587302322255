import React, { useReducer, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axiosInstance from "../../../utils/axiosInstance";
import { handleSetAuthCookie } from "../../../utils/token";
import useUserData from "../../../store/userStore";
import useAuthModal from "../../../store/authWarningModal";
import { toast } from "sonner";
import { handleGetLanguage } from "../../../utils/languageStorage";

const initialState = {
    name: "",
    email: "",
    phone_number: "",
    password: "",
    password_confirmation: "",
    iban: "",
    terms: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_NAME":
            return { ...state, name: action.payload };
        case "SET_USER_NUMBER":
            return { ...state, phone_number: action.payload };
        case "SET_USER_EMAIL":
            return { ...state, email: action.payload };
        case "SET_USER_PASSWORD":
            return { ...state, password: action.payload };
        case "SET_USER_CONFIRMPASSWORD":
            return { ...state, password_confirmation: action.payload };
        case "SET_IBAN":
            return { ...state, iban: action.payload };
        case "SET_TERMS":
            return { ...state, terms: action.payload };
        case "RESET":
            return initialState;
        default:
            return state;
    }
};

export default function Signup({ setTogglAuth }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const language = handleGetLanguage();
    const { setUsetData } = useUserData((state) => state);
    const { hide } = useAuthModal((state) => state);

    const signUpHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post("/register", {
                name: state.name,
                phone_number: state.phone_number,
                email: state.email,
                password: state.password,
                password_confirmation: state.password_confirmation,
                ...(state.iban !== "" && { iban: state.iban }),
            });
            handleSetAuthCookie(response.data.access_token);
            axiosInstance
                .get("/user")
                .then((res) => setUsetData(res.data))
                .catch((err) => console.log(err));
            dispatch({ type: "RESET" });
            hide();
        } catch (err) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <>
            <form
                className="flex flex-col items-center gap-4 w-full max-w-sm mx-auto p-4 z-999 "
                onSubmit={signUpHandler}
            >
                <h4 className="mb-8 font-bold text-lg text-black dark:text-white text-center">
                    {language === "ka" ? "რეგისტრაცია" : "Sign up for a new account"}
                </h4>
                <input
                    placeholder={language === "ka" ? "სახელი" : "Enter your name"}
                    type="text"
                    className="w-full py-3 px-4 rounded-full dark:bg-slate-900 text-black dark:text-white focus:outline-none focus:ring-0 shadow-lg"
                    value={state.name}
                    onChange={(e) => dispatch({ type: "SET_USER_NAME", payload: e.target.value })}
                />
                <input
                    placeholder={language === "ka" ? "ტელეფონის ნომერი" : "Enter your Phone number"}
                    type="text"
                    className="w-full py-3 px-4 rounded-full dark:bg-slate-900 text-black dark:text-white focus:outline-none focus:ring-0 shadow-lg"
                    value={state.phone_number}
                    onChange={(e) => dispatch({ type: "SET_USER_NUMBER", payload: e.target.value })}
                />
                <input
                    placeholder={language === "ka" ? "მეილი" : "Enter your email"}
                    type="text"
                    className="w-full py-3 px-4 rounded-full dark:bg-slate-900 text-black dark:text-white focus:outline-none focus:ring-0 shadow-lg"
                    value={state.email}
                    onChange={(e) => dispatch({ type: "SET_USER_EMAIL", payload: e.target.value })}
                />
                <div className="relative w-full">
                    <input
                        className="w-full py-3 px-4 rounded-full dark:bg-slate-900 text-black dark:text-white pr-10 focus:outline-none focus:ring-0 shadow-lg"
                        placeholder={language === "ka" ? "პაროლი" : "Enter your password"}
                        type={showPassword ? "text" : "password"}
                        value={state.password}
                        onChange={(e) =>
                            dispatch({ type: "SET_USER_PASSWORD", payload: e.target.value })
                        }
                    />
                    <div
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <AiOutlineEyeInvisible className="text-black dark:text-white" />
                        ) : (
                            <AiOutlineEye className="text-black dark:text-white" />
                        )}
                    </div>
                </div>
                <div className="relative w-full">
                    <input
                        className="w-full py-3 px-4 rounded-full dark:bg-slate-900 text-black dark:text-white pr-10 focus:outline-none focus:ring-0 shadow-lg"
                        placeholder={
                            language === "ka" ? "პაროლის დადასტურება" : "Confirm your password"
                        }
                        type={showConfirmPassword ? "text" : "password"}
                        value={state.password_confirmation}
                        onChange={(e) =>
                            dispatch({
                                type: "SET_USER_CONFIRMPASSWORD",
                                payload: e.target.value,
                            })
                        }
                    />
                    <div
                        className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        {showConfirmPassword ? (
                            <AiOutlineEyeInvisible className="text-black dark:text-white" />
                        ) : (
                            <AiOutlineEye className="text-black dark:text-white" />
                        )}
                    </div>
                </div>
                <div className="w-full hidden">
                    <label className="text-start ml-2 w-full text-gray-400 " htmlFor="iban">
                        {language === "ka" ? "არჩევითი :" : "optional :"}
                    </label>
                    <input
                        id="iban"
                        placeholder={"GEXXBGXXXXXXXXXXXXXXXX"}
                        type="text"
                        className="w-full py-3 px-4 rounded-full dark:bg-slate-900 text-black dark:text-white focus:outline-none focus:ring-0 shadow-lg"
                        value={state.iban}
                        onChange={(e) => dispatch({ type: "SET_IBAN", payload: e.target.value })}
                    />
                </div>
                <div className="w-[80%] text-sm ">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox"
                            checked={state.terms}
                            onChange={(e) =>
                                dispatch({ type: "SET_TERMS", payload: e.target.checked })
                            }
                        />
                        <a href="/rules" className="ml-2 text-black dark:text-white underline">
                            {language === "ka"
                                ? "ვეთანხმები წესებსა და პირობებს"
                                : "I agree to the terms and conditions"}
                        </a>
                    </label>
                </div>
                <div className="w-full">
                    <button
                        type="submit"
                        disabled={
                            !state.name ||
                            !state.email ||
                            !state.password ||
                            !state.password_confirmation ||
                            !state.terms
                        }
                        className="py-1.5 px-3 rounded-full bg-orange-500 text-white hover:opacity-75 mt-4 w-full disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        <p>{language === "ka" ? "რეგისტრაცია" : "Sign up"}</p>
                    </button>
                    <p className="text-center text-black dark:text-white mt-2">
                        {language === "ka" ? "გააქვს უკვე პროფილი?" : "Already have an account?"}
                    </p>
                    <button
                        onClick={() => setTogglAuth("signin")}
                        className="py-1.5 px-3 rounded-full bg-white text-black border border-black hover:bg-gray-100 mt-2 w-full"
                    >
                        <p>{language === "ka" ? "ავტორიზაცია" : "Sign in"}</p>
                    </button>
                </div>
            </form>
        </>
    );
}
