import React from "react";
import { Link } from "react-router-dom";

import Tagline from "../../components/tagline";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollToTop from "../../components/scroll-to-top";

import image1 from "../../assets/images/categories/ladies-ware.jpg";
import image2 from "../../assets/images/categories/kids-ware.jpg";
import image3 from "../../assets/images/categories/mens-ware.jpg";

import ShopItemCard from "../../components/shop-item-card";
import axiosInstance from "../../utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { handleGetLanguage } from "../../utils/languageStorage";
import { Helmet } from "react-helmet";

import BrandSlider from "../../components/brand-slider";
// Removed the Loading component import
// import Loading from "../../components/loading";

// Import the spinner from react-spinners
import { ClipLoader } from "react-spinners";
import useProductData from "../../store/productStore";

const fetchData = async () => {
    const response = await axiosInstance.get(`/products/dashboard`);
    if (response.status !== 200) {
        throw new Error("Something went wrong");
    }
    return response.data;
};

export default function IndexThree() {
    const { data, isLoading } = useQuery({
        queryKey: ["productsDashboard"],
        queryFn: () => fetchData(),
    });

    const language = handleGetLanguage();

    const translations = {
        en: {
            ladiesWear: "Ladies Wear",
            kidsWear: "Kids Wear",
            gentsWear: "Gents Wear",
            trendingItems: "Trending Items",
            shopLatest: "Shop the latest products from the most popular items",
            seeMore: "See More Items",
            endOfSeason: "End of Season Clearance Sale up to 30%",
            upgradeStyle:
                "Upgrade your style with our curated sets. Choose confidence, embrace your unique look.",
            shopNow: "See Rules",
            bestSellerItems: "Best Seller Items",
            popularCollections: "Shop the latest products from the most popular collections",
        },
        ka: {
            ladiesWear: "ქალბატონებისთვის",
            kidsWear: "ბავშვებისთვის",
            gentsWear: "მამაკაცებისთვის",
            trendingItems: "მოდური ნივთები",
            shopLatest: "იყიდეთ უახლესი პროდუქტები ყველაზე პოპულარული ნივთებიდან",
            seeMore: "იხილეთ მეტი ნივთები",
            endOfSeason: "სეზონის ბოლოს გაყიდვა 30% -მდე",
            upgradeStyle:
                "განაახლეთ თქვენი სტილი ჩვენი შერჩეული ნაკრებებით. აირჩიეთ თავდაჯერებულობა, მიაწოდეთ თქვენი უნიკალური სტილი.",
            shopNow: "იხილეთ წესები",
            bestSellerItems: "ყველაზე გაყიდვადი ნივთები",
            popularCollections: "იყიდეთ უახლესი პროდუქტები ყველაზე პოპულარული კოლექციებიდან",
        },
    };

    const t = translations[language] || translations.en;
    const { product } = useProductData((state) => state);
    return (
        <>
            {product?.name && (
                <Helmet>
                    <title>{product?.name[language]}</title>
                    <meta
                        property="og:title"
                        content={product?.name[language]}
                        data-react-helmet="true"
                    />
                    <meta
                        property="og:description"
                        content="100%-ით ორიგინალი"
                        data-react-helmet="true"
                    />
                    <meta
                        property="og:image"
                        content={"https://api.onlybrands.ge/storage/" + product.images[0]}
                        data-react-helmet="true"
                    />
                    <meta property="og:image:width" content="1200" data-react-helmet="true" />
                    <meta property="og:image:height" content="630" data-react-helmet="true" />
                    <meta
                        property="og:url"
                        content={product?.referralLink}
                        data-react-helmet="true"
                    />
                    <meta property="og:type" content="product" data-react-helmet="true" />
                    <meta property="og:site_name" content="OnlyBrands" data-react-helmet="true" />
                    <link rel="canonical" href={"referralLink"} />
                </Helmet>
            )}
            <Tagline />
            <Navbar navClass="defaultscroll is-sticky tagline-height" />
 

            <section className="relative mt-[70px] md:mt-0 md:pt-32">
                {/* <Slogan /> */}
                <BrandSlider />
                <div className="container-fluid relative">
                    <div className="grid md:grid-cols-3">
                        <div className="relative overflow-hidden group">
                            <Link to="/shop?gender=1" className="text-center">
                                <img
                                    src={image1}
                                    className="group-hover:scale-110 duration-500"
                                    alt=""
                                />
                                <span className="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium">
                                    {t.ladiesWear}
                                </span>
                            </Link>
                        </div>

                        <div className="relative overflow-hidden group">
                            <Link to="/shop?gender=3" className="text-center">
                                <img
                                    src={image2}
                                    className="group-hover:scale-110 duration-500"
                                    alt=""
                                />
                                <span className="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium">
                                    {t.kidsWear}
                                </span>
                            </Link>
                        </div>

                        <div className="relative overflow-hidden group">
                            <Link to="/shop?gender=2" className="text-center">
                                <img
                                    src={image3}
                                    className="group-hover:scale-110 duration-500"
                                    alt=""
                                />
                                <span className="bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium">
                                    {t.gentsWear}
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid items-end md:grid-cols-2 mb-6">
                        <div className="md:text-start text-center">
                            <h5 className="font-semibold text-3xl leading-normal mb-4">
                                {t.trendingItems}
                            </h5>
                            <p className="text-slate-400 max-w-xl">{t.shopLatest}</p>
                        </div>

                        <div className="md:text-end hidden md:block">
                            <Link
                                to="/shop?type=is_trending"
                                className="text-slate-400 hover:text-orange-500"
                            >
                                {t.seeMore} <i className="mdi mdi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
                        {isLoading ? (
                            <div className="flex justify-center items-center w-full h-64">
                                <ClipLoader size={50} color={"#FF5722"} loading={true} />
                            </div>
                        ) : (
                            data?.trendingItems?.map((item, index) => {
                                return <ShopItemCard key={index} item={item} />;
                            })
                        )}
                    </div>
                    <div className="grid grid-cols-1 mt-6">
                        <div className="text-center md:hidden block">
                            <Link
                                to="/shop?type=is_best_seller"
                                className="text-slate-400 hover:text-orange-500"
                            >
                                {t.seeMore} <i className="mdi mdi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section
                className="py-28 w-full table relative bg-center bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${"/referral.png"})` }}
            >
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="font-semibold text-3xl leading-normal mb-4 dark:text-black ">
                            {language === "ka"
                                ? "Onlybrands.ge ახდენს მხოლოდ ორიგინალი პროდუქციის რეალიზაციას, ასევე გთავაზობთ მარტივად გახდეთ ჩვენი პარტნიორი და მიიღოთ შემოსავალი."
                                : "Onlybrands.ge sells only original products, and also offers to easily become our partner and earn income."}
                        </h3>

                        <div className="mt-6">
                            <Link
                                to="/career"
                                className="py-2 px-5 inline-block font-semibold tracking-wide align-middle text-center text-white bg-orange-500 rounded-md hover:bg-orange-400"
                            >
                                <i className="mdi mdi-cart-outline"></i> {t.shopNow}
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 justify-center text-center mb-6">
                        <h5 className="font-semibold text-3xl leading-normal mb-4">
                            {t.bestSellerItems}
                        </h5>
                        <p className="text-slate-400 max-w-xl mx-auto">{t.popularCollections}</p>
                    </div>
                    <div className="md:text-end hidden md:block">
                        <Link
                            to="/shop?type=is_best_seller"
                            className="text-slate-400 hover:text-orange-500"
                        >
                            {t.seeMore} <i className="mdi mdi-arrow-right"></i>
                        </Link>
                    </div>

                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 pt-6 gap-6">
                        {isLoading ? (
                            <div className="flex justify-center items-center w-full h-64">
                                <ClipLoader size={50} color={"#FF5722"} loading={true} />
                            </div>
                        ) : (
                            data?.bestSeller?.map((item, index) => {
                                return <ShopItemCard key={index} item={item} />;
                            })
                        )}
                    </div>
                </div>
            </section>

            <Footer />
            <ScrollToTop />
        </>
    );
}
