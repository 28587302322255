import { create } from "zustand";

const useAuthModal = create((set) => ({
  isShown: false,
  type: null,

  show: (modalType) => set((state) => ({ isShown: true, type: modalType || null })),
  hide: () => set((state) => ({ isShown: false, type: null })),
}));

export default useAuthModal;
