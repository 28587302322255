import React from "react";
import { FacebookShareButton, FacebookIcon } from "react-share";

const ShareButton = ({ referralLink, product, language }) => {
    return (
        <div>
            <FacebookShareButton
                className="whitespace-nowrap py-2 px-5  font-semibold tracking-wide align-middle text-base text-center bg-orange-500 text-white rounded-md mt-2 hover:bg-orange-400 flex gap-2 items-center justify-center"
                url={referralLink}
                quote={`Check out this product: ${product.name[language]}`}
            >
                <FacebookIcon size={32} round />
                <span style={{ marginLeft: "8px" }}>Share on Facebook</span>
            </FacebookShareButton>
        </div>
    );
};

export default ShareButton;
