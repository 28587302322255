import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

const CategoriesMenuItem = ({
  category,
  language,
  updateQuery,
  categoriesQuery,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const isActive = +categoriesQuery === category.id;

  const linkTo =
    !category.children || category.children.length === 0
      ? location.pathname !== "/shop"
        ? updateQuery("categorie", category.id, "/shop")
        : updateQuery("categorie", category.id)
      : undefined;

  return (
    <li className="relative">
      {!category.children || category.children.length === 0 ? (
        <Link
          to={linkTo}
          onClick={toggleOpen}
          className={` block px-4 py-2 text-xl text-gray-800 hover:text-orange-500 transition-colors duration-300 dark:text-white${
            isActive ? "text-orange-500" : ""
          }`}
        >
          {category.name[language]}
        </Link>
      ) : (
        <div
          onClick={toggleOpen}
          className={`block px-4 py-2 text-sm text-gray-800 hover:text-orange-500 transition-colors duration-300 cursor-pointer dark:text-white 
                        
                    ${isActive ? "text-orange-500" : ""}`}
        >
          {category.name[language]}
          {category.children && category.children.length > 0 && (
            <FiChevronRight className="inline-block ml-2" />
          )}
        </div>
      )}
      {isOpen && category.children && category.children.length > 0 && (
        <ul className="pl-4 mt-2">
          {category.children.map((child) => (
            <CategoriesMenuItem
              key={child.id}
              category={child}
              language={language}
              updateQuery={updateQuery}
              categoriesQuery={categoriesQuery}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default CategoriesMenuItem;
