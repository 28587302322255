import React from "react";
import { Link } from "react-router-dom";

// import logoDark from "../../../assets/images/logo-dark.png";
// import logoLight from "../../../assets/images/logo-light.png";
import errorImg from "../../../assets/images/error.svg";

// import Switcher from "../../../components/switcher";
import BackToHome from "../../../components/back-to-home";
import { handleGetLanguage } from "../../../utils/languageStorage";

export default function Error() {
    const language = handleGetLanguage();

    const translations = {
        en: {
            pageNotFound: "Page Not Found?",
            description:
                "Whoops, this is embarrassing. Looks like the page you were looking for wasn't found.",
            backToHome: "Back to Home",
            designedBy: "© Onlybrands. Design & Develop with ",
            by: "by",
            heartIcon: <i className="mdi mdi-heart text-red-600"></i>,
            shreethemes: "QSOFT - Quantum Soft",
        },
        ka: {
            pageNotFound: "გვერდი ვერ მოიძებნა?",
            description: "უი, ეს დამღლელია. როგორც ჩანს, გვერდი, რომელსაც ეძებდით, ვერ მოიძებნა.",
            backToHome: "მთავარ გვერდზე დაბრუნება",
            designedBy: "© Onlybrands. შექმნილია ",
            by: "ავტორი",
            heartIcon: <i className="mdi mdi-heart text-red-600"></i>,
            shreethemes: "QSOFT - Quantum Soft",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <>
            <section className="relative bg-orange-500/5">
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="flex flex-col min-h-screen justify-center md:px-10 py-10 px-4">
                            <div className="text-center">
                                <Link to="/">
                                    <h1 className="text-4xl font-bold">OnlyBrands</h1>
                                </Link>
                            </div>
                            <div className="title-heading text-center my-auto">
                                <img src={errorImg} className="mx-auto w-72" alt="" />
                                <h1 className="mt-8 mb-6 md:text-5xl text-3xl font-bold">
                                    {t.pageNotFound}
                                </h1>
                                <p className="text-slate-400">{t.description}</p>

                                <div className="mt-4">
                                    <Link
                                        to="/"
                                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-orange-500 hover:bg-orange-600 border-orange-500 hover:border-orange-600 text-white rounded-md"
                                    >
                                        {t.backToHome}
                                    </Link>
                                </div>
                            </div>
                            <div className="text-center">
                                <p className="mb-0 text-slate-400">
                                    {t.designedBy} {new Date().getFullYear()} Onlybrands. {t.by}{" "}
                                    {t.heartIcon}{" "}
                                    <Link
                                        to="https://quantum-soft.org/"
                                        target="_blank"
                                        className="text-reset"
                                    >
                                        {t.shreethemes}
                                    </Link>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BackToHome />
        </>
    );
}
