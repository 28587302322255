import React, { useState } from "react";
import { FiHeart, FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import useUserData from "../store/userStore";
import useAuthModal from "../store/authWarningModal";
import { toast } from "sonner";
import { handleGetLanguage } from "../utils/languageStorage";

const ShopItemCard = ({ item }) => {
    const { user } = useUserData((state) => state);
    const { show } = useAuthModal((state) => state);
    const [isLiked, setIsLiked] = useState(item?.isLiked);

    const likeHandler = () => {
        axiosInstance
            .get(`/products/${item?.id}/like`)
            .then((res) => {
                if (res?.data.includes("unliked")) {
                    setIsLiked(false);
                    toast.success(res.data.message[language]);
                } else {
                    setIsLiked(true);
                    toast.success(res.data.message[language]);
                }
            })
            // TODO error handling
            .catch((err) => {
                toast.error(err.message[language]);
            });
    };

    const language = handleGetLanguage();

    return (
        <div className="group cursor-pointer relative">
            <Link
                to={`/product-detail/${item.id}`}
                className="overflow-hidden shadow dark:shadow-gray-800 group-hover:shadow-lg group-hover:dark:shadow-gray-800 rounded-md duration-500 block"
            >
                <img
                    src={
                        item?.images?.[0]?.includes("http")
                            ? item?.images?.[0]
                            : `https://api.onlybrands.ge/storage/${item?.images?.[0]}`
                    }
                    className="group-hover:scale-110 duration-500 w-full"
                    alt=""
                    style={{
                        height: "300px",
                        objectFit: "contain",
                        maxWidth: "100%",
                    }}
                />
            </Link>

            <ul className="list-none absolute top-[10px] end-4 opacity-0 group-hover:opacity-100 duration-500 space-y-1">
                <li>
                    <button
                        onClick={() => {
                            if (!user.id || !user.email) {
                                show();
                            } else {
                                likeHandler();
                            }
                        }}
                        className={`size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full hover:bg-slate-900 hover:text-white shadow`}
                        style={{
                            backgroundColor: isLiked ? "black" : "white",
                            color: isLiked ? "white" : "black",
                        }}
                    >
                        <FiHeart className="size-4" />
                    </button>
                </li>
                <li className="mt-1 ms-0">
                    <Link
                        to={`/product-detail/${item.id}`}
                        className="size-10 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center rounded-full bg-white text-slate-900 hover:bg-slate-900 hover:text-white shadow"
                    >
                        <FiEye className="size-4" />
                    </Link>
                </li>
            </ul>

            <div className="mt-4">
                <Link
                    to={`/product-detail/${item.id}`}
                    className="hover:text-orange-500 text-lg font-medium"
                >
                    {language === "ka" ? item.name.ka : item.name.en}
                </Link>
                <div className="flex justify-between items-center mt-1">
                    {item?.discounted_price ? (
                        <p>
                            GEL{item?.discounted_price}{" "}
                            {item.price && <del className="text-slate-400">GEL{item?.price}</del>}
                        </p>
                    ) : (
                        <p>GEL{item?.price}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShopItemCard;
