import React from "react";

const PaymentDecline = () => {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <h1 className="text-4xl text-red-500">Payment Decline</h1>
        </div>
    );
};

export default PaymentDecline;
