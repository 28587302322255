import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/css/tailwind.css";
import IndexThree from "./pages/index";
import ShopGridLeftSidebar from "./pages/shop/shop-grid/shop-grid-left-sidebar";
import Shopcart from "./pages/shop/shop-cart";
import ShopCheckOut from "./pages/shop/shop-checkout";
import UserSetting from "./pages/pages/my-account/user-setting";
import Career from "./pages/pages/career";
import Error from "./pages/pages/special/error";
import Contact from "./pages/contact";
import ProductDetailThree from "./pages/shop/shop-detail/product-detail-three";
import { Toaster } from "sonner";
import Modal from "./components/modal";
import useAuthMoadl from "./store/authWarningModal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axiosInstance from "./utils/axiosInstance";
import useUserData from "./store/userStore";
import { handleGetAuthCookie } from "./utils/token";
import Favorites from "./pages/pages/favorites";
import ProtectedRoutes from "./components/protected-routes";
import EmailConfirmation from "./pages/pages/email-template/user-orders";
import AboutUS from "./pages/pages/aboutus";
import useCartStore from "./store/cartStore";
import PaymentSuccess from "./pages/pages/paymentSuccess";
import PaymentDecline from "./pages/pages/paymentDecline";
import { HelmetProvider } from "react-helmet-async";

function App() {
    const queryClient = new QueryClient();
    const { isShown } = useAuthMoadl((state) => state);
    const { user, setUsetData } = useUserData((state) => state);
    const { initializeCart } = useCartStore((state) => state);
    const authCookie = handleGetAuthCookie();

    useEffect(() => {
        if (authCookie && (!user.name || !user.email)) {
            axiosInstance
                .get("/user")
                .then((res) => setUsetData(res.data))
                .catch((err) => console.log(err));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user.name && user.email) {
            initializeCart();
        }
    }, [user.name, user.email, initializeCart]);

    return (
        <>
            <HelmetProvider>
                <QueryClientProvider client={queryClient}>
                    {isShown && <Modal />}
                    <Routes>
                        <Route path="/" element={<IndexThree />} />
                        <Route path="/shop" element={<ShopGridLeftSidebar />} />
                        <Route element={<ProtectedRoutes isUser={authCookie} />}>
                            <Route path="/shop-cart" element={<Shopcart />} />
                            <Route path="/favorites" element={<Favorites />} />
                            <Route path="/shop-checkout" element={<ShopCheckOut />} />
                            <Route path="/user-setting" element={<UserSetting />} />
                            <Route path="/user-orders" element={<EmailConfirmation />} />
                            <Route path="/paymentSuccessed" element={<PaymentSuccess />} />
                            <Route path="/paymentDeclined" element={<PaymentDecline />} />
                        </Route>
                        <Route path="/rules" element={<Career />} />
                        <Route path="/career" element={<AboutUS />} />
                        <Route path="/product-detail/:id" element={<ProductDetailThree />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="*" element={<Error />} />
                    </Routes>
                    <Toaster />
                </QueryClientProvider>
            </HelmetProvider>
        </>
    );
}

export default App;
