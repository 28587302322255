import React, { useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { FiPhone, FiMail, FiMapPin, FiX } from "../assets/icons/vander";
import ScrollToTop from "../components/scroll-to-top";
import { handleGetLanguage } from "../utils/languageStorage";

export default function Contact() {
    const language = handleGetLanguage();
    let [modal, setModal] = useState(false);

    const translations = {
        en: {
            phone: "Phone",
            email: "Email",
            location: "Location",
            phoneDescription:
                "The phrasal sequence of the is now so that many campaign and benefit",
            emailDescription:
                "The phrasal sequence of the is now so that many campaign and benefit",
            locationDescription: "everywhere",
            viewOnGoogleMap: "everywhere",
        },
        ka: {
            phone: "ტელეფონი",
            email: "ელ.ფოსტა",
            location: "მდებარეობა",
            phoneDescription: "ახლა მრავალი კამპანიისა და სარგებლობის ფრაზული მიმდევრობაა",
            emailDescription: "ახლა მრავალი კამპანიისა და სარგებლობის ფრაზული მიმდევრობაა",
            locationDescription: "ყველგან",
            viewOnGoogleMap: "ყველგან",
        },
    };

    const t = translations[language] || translations.en;

    return (
        <>
            <div className="flex flex-col min-h-screen">
                <Navbar navClass="defaultscroll is-sticky" />

                <section className="flex-grow relative lg:py-24 py-16">
                    <div className="container lg:mt-24 mt-16">
                        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
                            <div className="text-center px-6">
                                <div className="relative text-transparent">
                                    <div className="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <FiPhone />
                                    </div>
                                </div>

                                <div className="content mt-7">
                                    <h5 className="title h5 text-lg font-semibold">{t.phone}</h5>

                                    <div className="mt-5">
                                        <p className="text-orange-500 font-medium">593 55 99 30</p>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center px-6">
                                <div className="relative text-transparent">
                                    <div className="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <FiMail />
                                    </div>
                                </div>

                                <div className="content mt-7">
                                    <h5 className="title h5 text-lg font-semibold">Facebook</h5>

                                    <div className="mt-5">
                                        <Link
                                            to="https://www.facebook.com/profile.php?id=61569978551603"
                                            className="text-orange-500 font-medium"
                                        >
                                            FB: Onlybrands.ge
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="text-center px-6">
                                <div className="relative text-transparent">
                                    <div className="size-20 bg-orange-500/5 text-orange-500 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                        <FiMapPin />
                                    </div>
                                </div>

                                <div className="content mt-7">
                                    <h5 className="title h5 text-lg font-semibold">{t.location}</h5>

                                    <div className="mt-5">
                                        <Link
                                            to="#"
                                            onClick={() => setModal(true)}
                                            data-type="iframe"
                                            className="video-play-icon read-more lightbox text-orange-500 font-medium"
                                        >
                                            {t.viewOnGoogleMap}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>

            {/* {modal && (
                <div className="w-full h-screen bg-slate-900/80 fixed top-0 left-0 bottom-0 right-0 z-999 flex items-center justify-center">
                    <div className="w-full h-full px-5 md:px-40 md-py-20 py-5">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55431.05581015953!2d-95.461302!3d29.735948000000004!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c36647a52ab1%3A0x70a301678672cb!2sBriargrove%20Park%2C%20Houston%2C%20TX%2C%20USA!5e0!3m2!1sen!2sin!4v1710322657489!5m2!1sen!2sin"
                            width="100%"
                            height="100%"
                            title="myfram"
                            loading="lazy"
                        ></iframe>
                    </div>
                    <button
                        className="text-slate-400 absolute top-[20px] right-[20px]"
                        onClick={() => setModal(false)}
                    >
                        <FiX className="size-5" />
                    </button>
                </div>
            )} */}

            <ScrollToTop />
        </>
    );
}
