import React, { useState, useEffect, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import { Navigation, Pagination } from "swiper/modules";

const prefix = `https://api.onlybrands.ge/storage/`;

const ImageCarousel = ({ images, setMainImage, mainImage }) => (
    <div className="flex md:flex-col gap-3 items-center">
        {images.map((item, index) => (
            <button
                key={index}
                onClick={() => setMainImage(item)}
                className={`p-1 rounded-md overflow-hidden ${
                    mainImage === item ? "border-2 border-blue-500" : "border border-gray-300"
                }`}
            >
                <img
                    src={prefix + item}
                    alt={`Thumbnail ${index}`}
                    className="h-[60px] w-[60px] md:h-[80px] md:w-[80px] object-cover hover:scale-105 transition-transform duration-150"
                />
            </button>
        ))}
    </div>
);

const ImageSwiper = ({ images, initialImage, onClose }) => {
    const [mainImage, setMainImage] = useState(initialImage);
    const swiperRef = useRef(null); // Ref to control the Swiper instance

    // Update Swiper when mainImage changes
    useEffect(() => {
        const newIndex = images.indexOf(mainImage);
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(newIndex);
        }
    }, [mainImage, images]);

    useEffect(() => {
        if (images.length === 0) {
            console.error("No images available!");
        }
    }, [images]);

    useEffect(() => {
        document.body.style.overflow = "hidden"; // Disable scroll
        return () => {
            document.body.style.overflow = "auto"; // Enable scroll on unmount
        };
    }, []);

    // Custom navigation logic
    const handlePrev = () => {
        swiperRef.current.swiper.slidePrev();
    };

    const handleNext = () => {
        swiperRef.current.swiper.slideNext();
    };

    return (
        <div className="fixed inset-0 z-999 flex flex-col-reverse md:flex-row items-center justify-center bg-white bg-opacity-70 p-4">
            <button
                onClick={onClose}
                className="absolute top-5 right-5 p-2 bg-white rounded-full text-black hover:scale-110 duration-150"
            >
                <IoMdClose className="h-5 w-5" />
            </button>

            {/* ImageCarousel should be under Swiper on mobile, and flex row */}
            <div className="flex md:flex-col flex-row gap-3 md:mr-16 items-center justify-center w-full md:w-auto">
                {/* ImageCarousel for thumbnails */}
                <ImageCarousel images={images} setMainImage={setMainImage} mainImage={mainImage} />
            </div>

            {/* Swiper */}
            <div className="relative w-full md:w-[70%] bg-white rounded-md p-4">
                <Swiper
                    ref={swiperRef} // Assign the ref to Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    loop={true}
                    pagination={{ clickable: true }}
                    modules={[Navigation, Pagination]}
                >
                    {images.map((image, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <img
                                    src={prefix + image}
                                    alt={`${index}`}
                                    className="w-full h-[70vh] md:h-[95vh] object-contain bn rounded-md"
                                />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                {/* Custom navigation buttons */}
                <button
                    onClick={handlePrev}
                    className="absolute left-5 top-1/2 transform -translate-y-1/2 p-3 text-orange-500 rounded-full text-3xl md:text-7xl hover:text-orange-400 duration-150 hidden md:block z-999"
                >
                    &#10094;
                </button>
                <button
                    onClick={handleNext}
                    className="absolute right-5 top-1/2 transform -translate-y-1/2 p-3 text-orange-500 rounded-full text-3xl md:text-7xl hover:text-orange-400 duration-150 hidden md:block z-999"
                >
                    &#10095;
                </button>
            </div>
        </div>
    );
};

export default ImageSwiper;
