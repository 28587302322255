import React from "react";

const PaymentSuccess = () => {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <h1 className="text-4xl text-green-500">Payment Success</h1>
        </div>
    );
};

export default PaymentSuccess;
