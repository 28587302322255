import React, { useState, useEffect } from "react";
import { handleGetLanguage } from "../utils/languageStorage";
import axiosInstance from "../utils/axiosInstance";
import { toast } from "sonner";

const IbanModal = ({ setIsOpen }) => {
    const [iban, setIban] = useState("");
    const language = handleGetLanguage();

    // Disable background scrolling when the modal is open
    useEffect(() => {
        // Add the overflow-hidden class to the body
        document.body.classList.add("overflow-hidden");

        // Remove the overflow-hidden class when the modal is closed
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    const handleSubmit = () => {
        axiosInstance
            .post("/user/attach-iban", { iban: iban })
            .then((res) => toast.success(res.data.message[language]))
            .catch((err) => toast.error(err.message[language]));
        setIsOpen(false);
    };

    const handleInputChange = (e) => {
        setIban(e.target.value);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-white/70 dark:bg-black/70 z-50 ">
            <div className="bg-white dark:bg-black dark:text-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
                <h2 className="text-xl font-semibold mb-4">
                    {language === "ka" ? "შეიყვანეთ ანგარიში ნომერი" : "Enter Account Number"}
                </h2>
                <input
                    type="text"
                    placeholder={"GEXXBGXXXXXXXXXXXXXXXX"}
                    value={iban}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-white dark:bg-transparent"
                />
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={() => setIsOpen(false)}
                        className="px-4 py-2 bg-gray-500 text-white rounded-md"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-2 bg-orange-500 text-white rounded-md"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default IbanModal;
